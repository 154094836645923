@import './theme';
@import '~vizzuality-components/dist/bundle';

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  padding-left: 20px;
}

// .mapboxgl-ctrl-bottom-right {
//   right: 80px;
// }

.mapboxgl-ctrl-attrib-inner > a {
  font-size: $fontXXSmall;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background: transparent;
}

.MuiTypography-body1 {
  font-size: $fontSmall !important;
  font-family: $primaryFontFamily !important;
}

.MuiFormControlLabel-root {
  margin: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: var(--primary-font-family) !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  // background-color: #c8def4;
}

.theme-light,
.theme-dark {
  background-color: $backgroundColor;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $primaryColor !important;
}

:focus {
  outline: none !important;
}

.planet-links {
  color: $primaryColor;
}

.planet-links:hover {
  color: $primaryColor;
}

.planet-links:visited {
  color: $primaryColor;
}

:export {
  primaryColor: $primaryColor;
  primaryFontColor: $primaryFontColor;
}

a {
  text-decoration: none;
}

// Reseting User agent styles to maintain cross browser interferences
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

// Deforestation slider
.rc-slider-mark-text {
  font-family: $primaryFontFamily;
  color: $primaryFontColor;
  font-size: $fontXSmall;
}

// Stepper Connector
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: $primaryColor !important;
}

.comparison-map {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mapboxgl-compare {
  top: 0;
  position: fixed;
}

.mapboxgl-compare .compare-swiper-vertical {
  background-color: $primaryColor;
}

// .mapboxgl-ctrl-bottom-right {
//   margin: 0 10px 40px 0 !important;
// }

// .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib-inner {
//   display: none;
// }

@font-face {
  font-family: 'Pampers Script';
  src: url('/tenants/pampers/Pampers Script.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.unDecadeFill {
  fill: $undecadeFill;
}

.iconFillNone {
  fill: none;
  stroke: $primaryFontColor;
}

.iconFillColor {
  fill: $primaryFontColor;
}

.primaryButton,
.secondaryButton {
  color: $light;
  font-weight: bold;
  background-color: $primaryColor;
  background-image: linear-gradient(
    103deg,
    $primaryColor 5%,
    $primaryDarkColor 116%
  );
  height: 40px;
  padding: 0px;
  text-align: center;
  border: 0px;
  border-radius: 52px;
  min-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;
}
.primaryButton:hover,
.secondaryButton:hover {
  transform: translateY(-7px);
  cursor: pointer;
}

.secondaryButton {
  min-width: 240px;
  background-image: none;
  background-color: $backgroundColorDark;
  color: $primaryFontColor;
  p {
    margin-left: 6px;
  }
  svg {
    height: 12px;
  }
}

button {
  border: 0px;
  padding: 0px;
  margin: 0px;
  background: transparent;
}

#customized-select-native {
  padding-left: 40px;
}

// .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
//   margin: 0 20px 25px 0;
// }

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-SemiBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
}

.notificationContainer {
  position: fixed;
  bottom: 50px;
  right: 70px;
  min-width: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  max-width: 98%;
  pointer-events: none;
  z-index: 999;
  height: fit-content;
  background: transparent;
}

@include xsPhoneView {
  .notificationContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 100px;
    max-width: 100%;
    border-radius: 0px;
  }
  .profilePage {
    padding-top: 80px !important;
  }
}

.read-more-button {
  font-weight: bold;
  cursor: pointer;
}

// Profile page
.profilePageTitle {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 20px;
  color: $primaryFontColor;
}
.profilePageSubTitle {
  font-size: 16px;
  margin-bottom: 20px;
  color: $primaryFontColor;
}
.profilePage {
  // max-width: 1000px;
  background-color: $backgroundBase;
  margin-bottom: 80px;
  padding: 20px;
  min-height: 100vh;
  @include smTabletView {
    padding: 40px;
    margin-bottom: 0px;
    margin-top: 80px;
  }
}

.profilePageHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topLoader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 5px;
  z-index: 1031;
}

// Loader for Buttons
.spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px !important;
  height: 16px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Form Styles

.formErrors {
  font-family: $primaryFontFamily;
  font-size: $fontXSmall;
  color: $dangerColor;
  margin-left: 6px;
  max-width: 320px;
  margin-bottom: -18px;
}

.vizzuality__legend-item-container {
  background-color: $backgroundColor;
}
.vizzuality__c-timestep .vizzuality__player-btn > svg {
  fill: $primaryFontColor;
}

.MuiSvgIcon-root,
.MuiNativeSelect-icon {
  path {
    fill: $primaryFontColor;
  }
}

.MuiStepLabel-label.MuiStepLabel-active,
.MuiStepLabel-label.MuiStepLabel-completed,
.MuiStepLabel-label {
  color: $primaryFontColor !important;
}
.MuiInputBase-input {
  color: $primaryFontColor !important;
}

.mapboxgl-ctrl-group {
  filter: $mapboxIcon;
}

input:autofill,
input:-webkit-autofill {
  background-color: $backgroundColorDark !important;
  color: $primaryFontColor !important;
}
.MuiSelect-icon,
.MuiNativeSelect-icon {
  fill: $primaryFontColor !important;
}
.MuiNativeSelect-select:not([multiple]) option,
.MuiNativeSelect-select:not([multiple]) optgroup {
  background-color: $backgroundColor !important;
}
.MuiAlert-message{
  color: $primaryFontColor!important;
}

.suggestions-container {
  position: absolute;
  top: 50px;
  z-index: 99999;
  color: $primaryFontColor;
  background-color: $backgroundColorDark;
  max-height: 200px;
  overflow: scroll;
  box-shadow: 0px 3px 6px -3px rgba($color: #000000, $alpha: 0.26);
  min-width: 100%;
  .suggestion {
    min-width: 100%;
    &:nth-child(even) {
      background-color: rgba(var(--background-color-dark),0.6);
    }
    padding: 12px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    &:hover{
      cursor: pointer;
      background-color: $backgroundColorDark;
    }
  }
}

.address-search  {
  position:absolute;
  top: 10px;
  right: 10px;
  max-width: 250px;
  width: 100%;
}
