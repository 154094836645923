@import "../../../theme/theme";
// for landing video

.landingVideoSection {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    .landingVideoWrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;
        video {
            width: 100vw!important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: auto!important;
        }
    }

    .landingVideoSkipButton {
        position: fixed;
        bottom: 30px;
        height: 50px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100px;
        padding-left: 24px;
        padding-right: 24px;
        min-width: 200px;
        max-width: 320px;
        display: flex;
        justify-content: center;
        background-color: #fff;
        color: $primaryColor;
        border: 2px solid $primaryColor;
        align-items: center;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        font-weight: bold;
        font-size: 16px;
    }
    .landingVideoSkipButton:hover {
        transform: scale(1.05);
        cursor: pointer;
    }

    @include smTabletView{
        .landingVideoSkipButton {
            left: auto;
            transform: none;
            right: 30px;
        }
    }
    @include mdTabletView{
        .landingVideoWrapper {
            video {
                height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
                min-height: 100vh;
                min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            }
        }
    }
}

// Loader for Buttons
.spinner {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid transparent;
    width: 16px !important;
    height: 16px !important;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.playButton {
  position: fixed;
  right: 55px;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  line-height: 16px;
  cursor: pointer;
  z-index: 11;
  > svg {
    width: 15px;
  }
}


@include smTabletView{

    .playButton {
        top: 100px;
      }
}
@include mdTabletView{

    .playButton {
        top: 110px;
        right: 125px;
        flex-direction: row;
      }
}
